import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "requesting-an-api-key"
    }}>{`Requesting an API Key`}</h1>
    <h2 {...{
      "id": "overview"
    }}>{`Overview`}</h2>
    <p>{`The Capella API uses API Keys to validate a user’s identity. `}</p>
    <p>{`You must have an active Capella Console account to successfully request an API Key.`}</p>
    <h2 {...{
      "id": "creating-an-api-key"
    }}>{`Creating an API Key`}</h2>
    <p>{`Create a new API key, by submitting a `}<inlineCode parentName="p">{`POST`}</inlineCode>{` request to the `}<inlineCode parentName="p">{`/keys`}</inlineCode>{` endpoint providing a `}<inlineCode parentName="p">{`description`}</inlineCode>{` in the query string and Authorization header as shown below:`}</p>
    <pre><code parentName="pre" {...{}}>{`POST "https://api.capellaspace.com/keys?description=My%20API%20Key"
Authorization: Bearer <token> | ApiKey <apiKey>
`}</code></pre>
    <p>{`A successful request will result in the following response:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
    "id":"cj7...",
    "description":"My API Key",
    "key_value":"AWIj...",
    "user_id":"12345678-1234-5678-1234-567812345678",
    "created_at":"2025-02-05T16:18:42.000000Z"
}
`}</code></pre>
    <p>{`The API Key will be returned in the `}<inlineCode parentName="p">{`key_value`}</inlineCode>{` field. This is the only time that the API Key can be viewed. If you lose or forget your API key, you cannot retrieve it again. Instead, create a new API Key and delete the old one.`}</p>
    <h2 {...{
      "id": "using-the-api-key"
    }}>{`Using the API Key`}</h2>
    <p>{`To use the API key you have requested, include it in the Authorization header of requests to other endpoints`}</p>
    <pre><code parentName="pre" {...{}}>{`Authorization: ApiKey {{apiKey}}
`}</code></pre>
    <h3 {...{
      "id": "api-key-best-practices"
    }}>{`API Key Best Practices`}</h3>
    <ul>
      <li parentName="ul">{`Never store your API Keys in plain text, in a code repository, or in code.`}</li>
      <li parentName="ul">{`Delete API Keys when no longer needed.`}</li>
      <li parentName="ul">{`Rotate API Keys regularly.`}</li>
    </ul>
    <h2 {...{
      "id": "additional-information"
    }}>{`Additional Information`}</h2>
    <p>{`For more information, see the API reference for `}<a parentName="p" {...{
        "href": "https://docs.capellaspace.com/api/keys"
      }}>{`Keys API`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      